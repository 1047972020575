import { TWITTER_VIDEO_URL } from '../constants';

/**
 * @function isVideoUrl
 * @param {string} url - The url to check
 * @returns {boolean} = Whether the url is a video url
 */
const isVideoUrl = url => {
  if (typeof url !== 'undefined') {
    return !!url.includes(TWITTER_VIDEO_URL);
  }

  return false;
};

/**
 * @function isValidHttpUrl
 * @param {string} string - The string to check
 * @returns {boolean} - Whether the string is a valid http url
 */
const isValidHttpUrl = string => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export { isValidHttpUrl, isVideoUrl };
